<template>
    <div style="position: relative">
      <div style="position: absolute; top: 6vh; left: 20vw; width: 50%">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="token:" prop="token">
            <el-input type="text" v-model="form.token"></el-input>
          </el-form-item>
          <el-form-item label="用户名:" prop="username">
            <el-input type="text" v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        form: {
            token: "",
          username:"",
          password:"",
        },
        rules: {
          token: [{ required: true, message: "请输入token", trigger: "blur" }],
          username: [{ required: true, message: "请输入账号", trigger: "blur" }],
          password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        },
      };
    },
    mounted() {
      this.getDetails()
    },
    methods: {
      // 获取回显信息
      getDetails(){

        this.$get("/insectManage/lastToken").then((res) => {
          if (res.data.state == "success") {
            this.form = {...res.data.data};
          }
        });
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if(valid){
            this.$confirm("是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.$postJSON('/insectManage/saveToken', {
                    ...this.form
                  }
              ).then((res) => {
                if (res.data.state == 'success') {
                  this.$message.success('保存成功')
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            });
          }else{
            //console.log("error submit!!");
            return false;

          }

        });

      },
    },
  };
  </script>
  